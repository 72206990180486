<template>
  <Dialog v-model:visible="display" :header="isAdd?'新增类型':'编辑类型'"
          style="width:500px"
          @hide="$emit('close')">
    <div class="p-field p-grid">
      <label for="pid" class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>父节点:</label>
      <div class="p-col">
        <TreeSelect id="pid" v-model="selectedValue" @node-select="nodeSelect"
                    selectionMode="single" :options="treeData">
        </TreeSelect>
        <small class="p-error" v-if="v$.form.pid.$invalid && form.$submitted">请选择父节点</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>名称:</label>
      <div class="p-col">
        <InputText v-model="form.name" :class="{'p-invalid':v$.form.name.$invalid && form.$submitted}"></InputText>
        <small class="p-error" v-if="v$.form.name.$invalid && form.$submitted">请输入名称</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label for="pid" class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>编号:</label>
      <div class="p-col">
        <InputText v-model="form.code" :class="{'p-invalid':v$.form.name.$invalid && form.$submitted}"></InputText>
        <small class="p-error" v-if="v$.form.code.$invalid && form.$submitted">请输入编号</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label for="pid" class="p-col-fixed">备注:</label>
      <div class="p-col">
        <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60"></Textarea>
        <!--        <small class="p-error" v-if="v$.form.roleId.$invalid && form.$submitted">请选择角色:</small>-->
      </div>
    </div>
    <template #footer>
      <Button @click="display=false" class="p-button-text">取消</Button>
      <Button @click="submit(v$.$invalid)">提交</Button>
    </template>

  </Dialog>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  setup: () => ({v$: useVuelidate()}),
  name: "addOrUpdateTreeNode",
  data() {
    return {
      display: false,
      form: {
        id: null,
        pid: null,
        name: null,
        code: null,
        comments: null,
        $submitted: false
      },
      isAdd: false,
      treeData: null,
      selectedValue: {}
    }
  },
  validations() {
    return {
      form: {
        id: {},
        pid: {},
        name: {required},
        code: {required},
        comments: {},
      }
    }
  },
  methods: {
    init(isAdd, data) {
      this.display = true
      this.getTreeData()
      this.isAdd = isAdd
      if (isAdd) {
        this.form.pid = data.id
      } else {
        this.form = data
      }
      this.selectedValue = {}
      if(data.pid) {
        this.selectedValue[data.pid] = true
      }
    },
    getTreeData() {
      this.$http('/dictType/getTreeData', 'get', null, (res) => {
        this.treeData = res.data
      })
    },
    nodeSelect(e) {
      this.form.pid = e.id
    },
    submit(isInvalid) {
      let me = this;
      this.form.$submitted = true;
      if (isInvalid) {
        return;
      }
      this.$http('/dictType/addOrUpdate', 'post', this.form, () => {
        me.$toast.add({
          severity: 'success',
          summary: '成功',
          detail: me.isAdd ? '类型添加成功！' : '修改成功！',
          life: 3000
        });
        me.display = false
      })
    }

  }
}
</script>

<style scoped>
label {
  width: 80px;
  text-align: right;
  justify-content: flex-end;
}

small {
  display: block;
}

::v-deep(.p-treeselect) {
  width: 100%
}

::v-deep(.p-inputtext) {
  width: 100%
}
</style>
