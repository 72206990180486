<template>
  <Dialog v-model:visible="show" :header="isAdd?'新增子条目':'编辑子条目'"
          style="width:500px"
          @hide="$emit('close')">
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>字典项名称:</label>
      <div class="p-col">
        <InputText v-model="form.itemKey" :class="{'p-invalid':v$.form.itemKey.$invalid && form.$submitted}"></InputText>
        <small class="p-error" v-if="v$.form.itemKey.$invalid && form.$submitted">请输入名称</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>字典项值:</label>
      <div class="p-col">
        <InputText v-model="form.itemValue" :class="{'p-invalid':v$.form.itemValue.$invalid && form.$submitted}"></InputText>
        <small class="p-error" v-if="v$.form.itemValue.$invalid && form.$submitted">请输入值</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>语言:</label>
      <div class="p-col">
        <Dropdown v-model="form.lang" :options="[{
          label:'中文',value:'en'
        }]" option-label="label" option-value="value"
                  :class="{'p-invalid':v$.form.lang.$invalid && form.$submitted}"></Dropdown>
        <small class="p-error" v-if="v$.form.lang.$invalid && form.$submitted">请输入语言</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">备注:</label>
      <div class="p-col">
        <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60"></Textarea>
        <!--        <small class="p-error" v-if="v$.form.roleId.$invalid && form.$submitted">请选择角色:</small>-->
      </div>
    </div>
    <template #footer>
      <Button @click="show=false" class="p-button-text">取消</Button>
      <Button @click="submit(v$.$invalid)">提交</Button>
    </template>

  </Dialog>
</template>

<script>
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  name: "addOrUpdateDictItem",
  data() {
    return {
      show: false,
      form: {
        id: null,
        dictId: null,
        itemKey: null,
        itemValue: null,
        lang: null,
        comments: null,
        $submitted: false
      },
      isAdd: false,
    }
  },
  validations() {
    return {
      form: {
        id: {},
        dictId: {},
        itemKey: {required},
        itemValue: {required},
        lang: {required},
        comments: {},
      }
    }
  },
  methods: {
    init(data) {
      this.show = true
      if (data.id) {
        this.isAdd = false
        this.form=data
      } else {
        this.isAdd = true
        this.form.dictId=data.dictId
      }
    },

    submit(isInvalid) {
      let me = this;
      this.form.$submitted = true;
      if (isInvalid) {
        return;
      }
      this.$http('/dictItem/addOrUpdate', 'post', this.form, () => {
        me.$toast.add({
          severity: 'success',
          summary: '成功',
          detail: me.isAdd ? '类型添加成功！' : '修改成功！',
          life: 3000
        });
        me.show = false
      })
    }

  }
}
</script>

<style scoped>
label {
  width: 120px;
  text-align: right;
  justify-content: flex-end;
}

small {
  display: block;
}


::v-deep(.p-inputtext) {
  width: 100%
}
</style>
