<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class=" card">
    <Splitter style="border:none">
      <SplitterPanel :size="20">
        <h5>数据字典类型</h5>
        <Tree :value="treeData" selectionMode="single" v-model:selection-keys="selectedKey" :expandedKeys="expandedKeys"
              @contextmenu="contextmenu($event)" @node-select="treeNodeSelect"
              @node-unselect="selectedNode=null">
        </Tree>
        <ContextMenu ref="menu" :model="rightMenu" style="width: 90px">
          <template #item="{item}">
            <p :style="{color:item.color,padding:'0.5rem 1rem',margin:0,cursor:'pointer'}"
               @click="toFunction(item.key)">{{ item.label }}</p>
          </template>
        </ContextMenu>
      </SplitterPanel>
      <SplitterPanel :size="80" style="padding-left: 1rem">
        <div class="p-mb-3">
          <Button v-permission="['1010','shujuzidian','xjzd']" :disabled="selectedNode&&selectedNode.id?null:'disabled'"
                  @click="addOrUpdateMain">新建字典
          </Button>
        </div>
        <DataTable :value="treeTable" :row-hover="true" v-model:selection="selectedRow" selection-mode="single"
                   @row-click="itemTable">
          <template #empty>
            无数据。
          </template>
          <Column field="name" header="名称"></Column>
          <Column field="code" header="编码"></Column>
          <Column field="comments" header="备注"></Column>
          <Column header="操作">
            <template #body="row">
              <Button v-permission="['1010','shujuzidian','zdbj']" class="p-button-text"
                      @click="addOrUpdateMain(row.data)">编辑
              </Button>
              <Button v-permission="['1010','shujuzidian','zdsc']" class="p-button-text" @click="deleteMain(row.data)">
                删除
              </Button>
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="page.size" :totalRecords="page.total" @page="changePage"
                   template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
                   :rowsPerPageOptions="[10,20,50,100]"
                   currentPageReportTemplate="共{totalRecords} 条记录">
          <template #right>
            <label>跳至&nbsp;&nbsp;</label>
            <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                         inputStyle="width: 40px;"/>
            <label>&nbsp;&nbsp;页</label>
          </template>
        </Paginator>
        <dict-item-table ref="item"></dict-item-table>
      </SplitterPanel>
    </Splitter>
    <add-or-update-tree-node v-if="treeNodeDataDisplay" ref="treeNodeData"
                             @close="closeDialog"></add-or-update-tree-node>
    <add-or-update-dict-main v-if="aouMainDisplay" ref="aouMain" @close="closeDialog"></add-or-update-dict-main>
  </div>
</template>

<script>
import AddOrUpdateTreeNode from "@/views/modules/dataDictionary/addOrUpdateTreeNode";
import DictItemTable from "@/views/modules/dataDictionary/dictItemTable";
import AddOrUpdateDictMain from "@/views/modules/dataDictionary/addOrUpdateDictMain";

export default {
  name: "dataDictionary",
  components: { AddOrUpdateDictMain, DictItemTable, AddOrUpdateTreeNode },
  data() {
    return {
      breadcrumb: {
        home: { label: "系统管理", to: "#" },
        items: [{ label: "数据字典", to: "#" }],
      },
      treeData: null,
      rightMenu: [
        { label: "编辑", key: 0 },
        { label: "新增", key: 1 },
        { label: "删除", color: "red", key: 2 },
      ],
      treeNodeDataDisplay: false,
      selectedNode: null,
      selectedKey: {},
      treeTable: null,
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      itemDisplay: false,
      aouMainDisplay: false,
      expandedKeys: {},
      selectedRow: null,
    };
  },
  mounted() {
    this.getTreeData();
  },
  methods: {
    getTreeData() {
      this.$http("/dictType/getTreeData", "get", null, (res) => {
        this.treeData = res.data;
        this.expandedKeys[res.data[0].key] = true;
      });
    },
    getList() {
      this.$http(
        "/dictMain/getList",
        "get",
        {
          typeId: this.selectedNode.id,
          size: this.page.size,
          current: this.page.current,
        },
        (res) => {
          this.treeTable = res.data.records;
          this.page.total = res.data.total;
        }
      );
    },
    contextmenu(event) {
      this.$refs.menu.show(event);
    },
    toFunction(type) {
      if (type === 0) {
        this.addOrUpdateTreeNode(false, this.selectedNode);
      } else if (type === 1) {
        this.addOrUpdateTreeNode(true, this.selectedNode);
      } else if (type === 2) {
        this.deleteTreeNode(this.selectedNode);
      }
    },
    addOrUpdateTreeNode(isAdd, data) {
      this.treeNodeDataDisplay = true;
      this.$nextTick(() => {
        this.$refs.treeNodeData.init(isAdd, data);
      });
    },
    deleteTreeNode(data) {
      if (data.children && data.children.length > 0) {
        this.$toast.add({
          severity: "warn",
          summary: "注意",
          detail: "此节点存在子节点不能删除！",
          life: 3000,
        });
      } else {
        this.$confirm.require({
          header: "删除",
          message: '您确定要删除"' + data.name + '"吗',
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "确认",
          rejectLabel: "取消",
          accept: () => {
            this.$http(
              "/dictType/deleteById",
              "get",
              { id: data.id },
              () => {
                this.$toast.add({
                  severity: "success",
                  summary: "成功",
                  detail: "删除成功",
                  life: 3000,
                });
                this.$confirm.close();
                this.getTreeData();
              },
              () => {
                this.$toast.add({
                  severity: "warn",
                  summary: "失败",
                  detail: "删除类型存在字典，不能删除！",
                  life: 3000,
                });
                this.$confirm.close();
              }
            );
          },
          reject: () => {
            this.$confirm.close();
          },
        });
      }
    },
    treeNodeSelect(data) {
      this.selectedNode = data;
      if (data.code === "all") {
        this.rightMenu = [
          { label: "编辑", key: 0 },
          { label: "新增", key: 1 },
        ];
      } else {
        this.rightMenu = [
          { label: "编辑", key: 0 },
          { label: "新增", key: 1 },
          { label: "删除", color: "red", key: 2 },
        ];
      }
      this.getList();
      this.$nextTick(() => {
        this.$refs.item.treeNodeChange();
      });
    },
    closeDialog() {
      this.treeNodeDataDisplay = false;
      this.itemDisplay = false;
      this.aouMainDisplay = false;
      this.getTreeData();
      this.getList();
    },
    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },
    itemTable(row) {
      this.itemDisplay = true;
      this.$nextTick(() => {
        this.$refs.item.init(row.data.id);
      });
    },
    deleteMain(data) {
      this.$confirm.require({
        header: "删除",
        message: '您确定要删除"' + data.name + '"吗',
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$http("/dictMain/delete/" + data.id, "delete", null, () => {
            this.$toast.add({
              severity: "success",
              summary: "成功",
              detail: "删除成功",
              life: 3000,
            });
            this.$confirm.close();
            this.getList();
          });
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },
    addOrUpdateMain(data) {
      this.aouMainDisplay = true;
      this.$nextTick(() => {
        data.dictId = this.selectedNode.id;
        this.$refs.aouMain.init(data);
      });
    },
  },
};
</script>

<style scoped>
::v-deep(.p-tree) {
  border: none;
  padding: 0;
  cursor: pointer;
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}

::v-deep(.p-datatable .p-column-header-content) {
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}
</style>
