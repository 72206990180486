<template>
  <Dialog v-model:visible="show" :header="isAdd?'新增字典':'编辑字典'"
          style="width:500px"
          @hide="$emit('close')">
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>字典名称:</label>
      <div class="p-col">
        <InputText v-model="form.name" :class="{'p-invalid':v$.form.name.$invalid && form.$submitted}"></InputText>
        <small class="p-error" v-if="v$.form.name.$invalid && form.$submitted">请输入名称</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">
        <span style="color:red;margin-right: 4px;">*</span>字典编号:</label>
      <div class="p-col">
        <InputText v-model="form.code" @blur="hasMainCode(form.code)"
                   :class="{'p-invalid':hasCode||(v$.form.code.$invalid && form.$submitted)}"></InputText>
        <small class="p-error" v-if="v$.form.code.$invalid && form.$submitted">请输入编号</small>
        <small class="p-error" v-if="hasCode">编号已存在</small>
      </div>
    </div>
    <div class="p-field p-grid">
      <label class="p-col-fixed">备注:</label>
      <div class="p-col">
        <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60"></Textarea>
        <!--        <small class="p-error" v-if="v$.form.roleId.$invalid && form.$submitted">请选择角色:</small>-->
      </div>
    </div>
    <template #footer>
      <Button @click="show=false" class="p-button-text">取消</Button>
      <Button @click="submit(v$.$invalid)">提交</Button>
    </template>

  </Dialog>
</template>

<script>
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  name: "addOrUpdateDictMain",
  data() {
    return {
      show: false,
      form: {
        id: null,
        name: null,
        code: null,
        typeId: null,
        comments: null,
        $submitted: false
      },
      isAdd: false,
      hasCode: false
    }
  },
  validations() {
    return {
      form: {
        id: {},
        name: {required},
        code: {required},
        typeId: {},
        comments: {},
      }
    }
  },
  methods: {
    init(data1) {
      this.show = true
      let data = JSON.parse(JSON.stringify(data1))
      if (data.id) {
        this.isAdd = false
        this.form = data
      } else {
        this.isAdd = true
        this.form.typeId = data.dictId
      }
    },

    hasMainCode(code) {
      this.$http('/dictMain/hasMainCode/' + code, 'get', null, (res) => {
        this.hasCode = res.data
      })
    },
    submit(isInvalid) {
      let me = this;
      this.form.$submitted = true;
      if (isInvalid || this.hasCode) {
        return;
      }
      this.$http('/dictMain/addOrUpdate', 'post', this.form, () => {
        me.$toast.add({
          severity: 'success',
          summary: '成功',
          detail: me.isAdd ? '类型添加成功！' : '修改成功！',
          life: 3000
        });
        me.show = false
      })
    }

  }
}
</script>

<style scoped>
label {
  width: 100px;
  text-align: right;
  justify-content: flex-end;
}

small {
  display: block;
}


::v-deep(.p-inputtext) {
  width: 100%
}
</style>
